// Custom Validations
const moment = require('moment')

export function validatePhone (phone) {
	const regJP = /^[0-9]{3}\s*-\s*[0-9]{4}\s*-\s*[0-9]{4}$|^[0-9]{2}\s*-\s*[0-9]{4}\s*-\s*[0-9]{4}$|^[0-9]{4}\s*-\s*[0-9]{2}\s*-\s*[0-9]{4}$/
	const regCA = /^\([0-9]{3}\)\s*[0-9]{3}\s*-\s*[0-9]{4}$/
	return regJP.test(phone) || regCA.test(phone)
}

export function isDefined (value) {
	return typeof value !== 'undefined' && value !== null
}

export function isEmpty (value) {
	return !isDefined(value) || (typeof value === 'string' && value.trim().length < 1)
}

export function validateDate (date) {
	const expDate = moment(date, ['MM / YYYY', 'MM / YY'], true)
	return expDate.isValid() && expDate.isAfter(moment.now())
}

export function validateCardNumber (cardNumber) {
	const regJP = /^[0-9]{14,16}$/
	const regCardFormatted = /^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$|^[0-9]{4}-[0-9]{6}-[0-9]{5}$|^[0-9]{4}-[0-9]{6}-[0-9]{4}$/
	return regJP.test(cardNumber) || regCardFormatted.test(cardNumber)
}

export function validateSecurityCode (cvv) {
	const regCVV = /^[0-9]{3}$/
	return regCVV.test(cvv)
}

export function validateCardHolder (cardHolder) {
	// Do not allow half-width or special characters in JP
	const regGMOCardHolderJP = /^[^#$@!~*^&%()_><,.;:"'?/`|\\{}\][ﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜﾝﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏｰｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿ｡｢｣､･ｦｧｨｩｪｫｬｭｮｯ]{1,51}$/
	return regGMOCardHolderJP.test(cardHolder)
}

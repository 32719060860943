<script>
import BaseHeadingTable from 'lowfoot-components/BaseHeadingTable'
import { isEmpty } from '@/helpers/validate.js'
import CardStore from '@/models/Card'
import ContentGroupService from '@/services/ContentGroupService'
import EnrollmentService from '@/services/EnrollmentService'
import CustomerFieldService from '@/services/CustomerFieldService'
import orderBy from 'lodash/orderBy'
import reloadPageMixin from '@/mixins/reload-page-mixin.js'

export default {
	name: 'Confirmation',
	components: { BaseHeadingTable },
	mixins: [reloadPageMixin],
	data: () => ({
		layout: 'public-layout',
		tableStructure: []
	}),
	activated: async function () {
		this.$store.commit('cms/setConfirmationCheckpoint', true)
		this.enrollmentService = await EnrollmentService.get()
		if (this.enrollmentService) await this.loadTableData()
		if (this.tableStructure.length < 1) {
			await this.$router.push({ name: 'error' })
		}
	},
	computed: {
		isEnrollmentComplete: function () {
			return this.enrollmentService && this.enrollmentService.isComplete()
		},
		cards: function () {
			return CardStore.query().withAllRecursive().orderBy('order').get()
		}
	},
	methods: {
		loadTableData: async function () {
			const confirmationFields = this.getConfirmationFields()
			this.createFieldTable(confirmationFields)
		},
		getConfirmationFields: function () {
			let cardCustomerFields = []
			let contentGroupCustomerFields = []
			let confirmationFields = []
			for (const card of this.cards) {
				const cardId = card.id
				cardCustomerFields = this.getCardCustomerFieldServices(card)
				contentGroupCustomerFields = this.getContentGroupCustomerFieldServices(cardId)
				confirmationFields.push([card.name, [...cardCustomerFields, ...contentGroupCustomerFields]])
			}
			// confirmationFields follows the Object.entries() return structure. https://mzl.la/3didlRJ
			return confirmationFields
		},
		getCardCustomerFieldServices: function (card) {
			const cardContents = orderBy(card.cardContents, 'order', 'asc')
			return cardContents.filter((cardContent) => cardContent.contentItem && cardContent.contentItem.contentType === 'CUSTOMER_FIELD')
				.map((cardContent) => {
					return CustomerFieldService.getByCustomerFieldTypeId(this.enrollmentService, cardContent.contentItem.customerFieldTypeId)
				}).filter((customerFieldService) => customerFieldService.getCustomerFieldType().enableConfirmation)
		},
		getContentGroupCustomerFieldServices: function (cardId) {
			const contentGroupServices = ContentGroupService.getAllByCardId(cardId)
				.map(contentGroup => new ContentGroupService(this.enrollmentService, contentGroup.id))
			let groupCustomerFieldServices = []
			for (const contentGroupService of contentGroupServices) {
				if (contentGroupService.isVisible()) {
					const customerFieldServices = contentGroupService.getContentGroupItems('CUSTOMER_FIELD')
						.map((contentGroupItem) =>
							CustomerFieldService.getByCustomerFieldTypeId(this.enrollmentService, contentGroupItem.contentItem.customerFieldTypeId))
						.filter((customerFieldService) => customerFieldService.getCustomerFieldType().enableConfirmation)
					groupCustomerFieldServices = [...groupCustomerFieldServices, ...customerFieldServices]
				}
			}
			return groupCustomerFieldServices
		},
		createFieldTable: function (confirmationFields) {
			this.tableStructure = []
			for (const [heading, customerFieldServices] of confirmationFields) {
				const fields = this.createFields(customerFieldServices)
				if (fields.length > 0) this.tableStructure.push({ heading, fields })
			}
		},
		createFields: function (customerFieldServices) {
			return customerFieldServices.map(customerFieldService => {
				const value = customerFieldService.getDisplayedValue() || customerFieldService.getValue()
				const label = customerFieldService.getLabel()
				return { label, value }
			}).filter((field) => !isEmpty(field.value))
		},
		goToEnrollment: async function () {
			await this.$router.push({ name: 'enrollment', hash: '#card-1' })
		},
		goToTermsConditions: async function () {
			await this.$router.push({ name: 'termsConditions', hash: '#terms-section' })
		}
	}
}
</script>
<template>
	<component :is="layout" class="confirmation">
		<div class="confirmation__body"
			id="confirmation-section"
			v-show="tableStructure && tableStructure.length > 0 && enrollmentService">
			<h2 class="confirmation__title">{{ $t('confirmation.title') }}</h2>
			<div class="confirmation__table-container">
				<base-heading-table class="confirmation__table" :table-structure="tableStructure"/>
			</div>
			<base-button modifiers="accent large margin-y"
						:disabled="isEnrollmentComplete"
						@click="goToTermsConditions()">
				<slot name="confirmationContinue">{{ $t('confirmation.continue') }}</slot>
			</base-button>
			<div class="confirmation__link" @click="goToEnrollment()">
				<slot name="confirmationCancel">{{ $t('confirmation.cancel') }}</slot>
			</div>
		</div>
	</component>
</template>

<style lang="scss" scoped>
.confirmation {
	&__body {
		align-items: center;
		background-color: white;
		border: 1px solid var(--border-variant-light);
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		padding: 2rem 1rem;
		transition: all 3s ease-in;
	}
	&__table {
		border-radius: var(--border-radius);
		max-height: 50vh;
		overflow: auto;
		&-container {
			width: 100%;
			margin-bottom: 1.5rem;
			padding: 0 1rem;
		}
		::v-deep .base-heading-table__heading {
			background-color: #f9f9f9;
			font-size: 1rem;
			overflow: hidden;
		}
		::v-deep .base-heading-table__label {
			font-size: 0.9rem;
		}
	;
	}
	&__title {
		margin-bottom: 1rem;
		text-align: center;
		font-size: var(--font-size-subheading);
		font-weight: var(--font-weight-subheading);
		letter-spacing: var(--letter-spacing-expanded);
	}
	&__link {
		filter: brightness(80%);
		color: var(--link-colour);
		margin-top: 1rem;
		font-size: 1.1rem;
		cursor: pointer;
	}
}
</style>
